import React from "react"
import PageHeader from "../../components/PageHeader/PageHeader"
import NavPages from "../../components/NavPages/NavPages"
import Flexbox from "../../components/Generator/Flexbox/Flexbox"
import Seo from "../../components/Seo/Seo"
import Footer from "../../components/Footer/Footer"

const FlexboxPage = ({ location }) => {
  const language = location.pathname.substr(1, 2)
  return (
    <>
      <Seo
        title="CSS Flexbox generator | CSSGENERATOR.PL"
        description="CSS FLEXBOX GENERATOR | An easy to use tool that allows you to generate ready CSS rules. Adjust values and copy the finished code."
        location={location}
        language={language}
        hreflang
      />
      <PageHeader
        h1="Flebox Generator"
        desc="An easy to use tool that allows you to generate ready CSS rules. Adjust flexbox values and copy the ready code."
        language={language}
      />
      <NavPages language={language} />
      <Flexbox language={language} />
      <Footer language={language} />
    </>
  )
}

export default FlexboxPage
